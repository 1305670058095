export const REQUEST_CASH_FULLFILLMENT_DETAILS =
  'zola/giftTracker/REQUEST_CASH_FULLFILLMENT_DETAILS';
export const RECEIVED_CASH_FULLFILLMENT_DETAILS =
  'zola/giftTracker/RECEIVED_CASH_FULLFILLMENT_DETAILS';
export const REQUEST_SUBMIT_FULLFILLMENT = 'zola/giftTracker/REQUEST_SUBMIT_FULLFILLMENT';
export const RECEIVED_SUBMIT_FULLFILLMENT = 'zola/giftTracker/RECEIVED_SUBMIT_FULLFILLMENT';
export const REQUEST_GIFT_FULLFILLMENT_DETAILS =
  'zola/giftTracker/REQUEST_GIFT_FULLFILLMENT_DETAILS';
export const RECEIVED_GIFT_FULLFILLMENT_DETAILS =
  'zola/giftTracker/RECEIVED_GIFT_FULLFILLMENT_DETAILS';
