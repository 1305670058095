import * as ActionTypes from '../actions/types/ExternalActionTypes';

const initialState = {
  splitOrderId: null,
  token: null,
  quantity: 1,
  giftGiverName: null,
};

const externalReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.UPDATE_EXTERNAL:
      return Object.assign({}, state, action.payload);
    case ActionTypes.PLACED_ORDER: {
      const { splitOrderId, token } = action.payload.data;
      return Object.assign({}, state, { splitOrderId, token });
    }
    case ActionTypes.CANCELLED_ORDER:
      return initialState;
    case ActionTypes.RESET:
      return initialState;
    default: {
      return state;
    }
  }
};

export default externalReducer;
