export const REQUEST_ELIGIBLE_PRODUCT = 'zola/registry/REQUEST_ELIGIBLE_PRODUCT';
export const RECEIVED_ELIGIBLE_PRODUCT = 'zola/registry/RECEIVED_ELIGIBLE_PRODUCT';
export const REQUEST_CREATE_REVIEW = 'zola/registry/REQUEST_CREATE_REVIEW';
export const RECEIVED_CREATE_REVIEW = 'zola/registry/RECEIVED_CREATE_REVIEW';
export const REQUEST_REVIEW_DETAILS = 'zola/registry/REQUEST_REVIEW_DETAILS';
export const RECEIVED_REVIEW_DETAILS = 'zola/registry/RECEIVED_REVIEW_DETAILS';
export const ACCEPT_CONDITION = 'zola/registry/ACCEPT_CONDITION';
export const REQUEST_UPDATE_REVIEW = 'zola/registry/REQUEST_UPDATE_REVIEW';
export const RECEIVED_UPDATE_REVIEW = 'zola/registry/RECEIVED_UPDATE_REVIEW';
export const REQUEST_REVIEW_STATUS = 'zola/registry/REQUEST_REVIEW_STATUS';
export const RECEIVED_REVIEW_STATUS = 'zola/registry/RECEIVED_REVIEW_STATUS';
export const SET_ACTIVE_PRODUCT = 'zola/registry/SET_ACTIVE_PRODUCT';
export const UNSET_ACTIVE_PRODUCT = 'zola/registry/UNSET_ACTIVE_PRODUCT';
export const UNSET_ACTIVE_REVIEW = 'zola/registry/UNSET_ACTIVE_REVIEW';
