import { createAction } from '@reduxjs/toolkit';
import type { WModuleGroupView } from '@zola/svc-web-api-ts-client';

const REQUEST_MODULE_GROUP = 'zola/module-group/REQUEST_MODULE_GROUP';
const RECEIVE_MODULE_GROUP = 'zola/module-group/RECEIVE_MODULE_GROUP';

export const requestModuleGroup = createAction(REQUEST_MODULE_GROUP);

export const receiveModuleGroup = createAction<{
  tag: string;
  data: WModuleGroupView;
}>(RECEIVE_MODULE_GROUP);
