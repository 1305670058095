import * as ActionTypes from '../actions/types/GiftTrackerActionTypes';

const GiftTrackerReducer = (state = {}, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_CASH_FULLFILLMENT_DETAILS:
      return {};
    case ActionTypes.RECEIVED_CASH_FULLFILLMENT_DETAILS:
      return { ...state, cashFullfillmentDetails: action.payload };
    case ActionTypes.REQUEST_GIFT_FULLFILLMENT_DETAILS:
      return {};
    case ActionTypes.RECEIVED_GIFT_FULLFILLMENT_DETAILS:
      return { ...state, giftFullfillmentDetails: action.payload };
    case ActionTypes.REQUEST_SUBMIT_FULLFILLMENT:
      return { ...state };
    case ActionTypes.RECEIVED_SUBMIT_FULLFILLMENT:
      return { confirmationDetails: action.payload };
    default:
      return state;
  }
};

export default GiftTrackerReducer;
