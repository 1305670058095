import * as ActionTypes from '../actions/types/NotificationActionTypes';

const initialState = [];

const notificationsReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case ActionTypes.SHOW_NOTIFICATION:
      return [...state, action.payload];
    case ActionTypes.HIDE_NOTIFICATION:
      return state.filter((notification) => notification.id !== action.payload);
    case ActionTypes.CLEAR_NOTIFICATIONS:
      return [];
    default:
      return state;
  }
};

export default notificationsReducer;
