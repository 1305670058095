import type { WProductLookView } from '@zola/svc-web-api-ts-client';
import { Reducer } from 'redux';
import * as ActionTypes from '../actions/types/DigitalGreetingCardActionTypes';
import { DigitalGreetingCardsActions } from '../actions/DigitalGreetingCardActions/types';

interface DigitalGreetingCardsState {
  defaultProductLookUUID?: string | null;
  productLookViews: WProductLookView[];
  productUUID?: string | null;
  selectedDGCTemplatePages: any;
  selectedDGCUUID: string | null;
}

const initialState: DigitalGreetingCardsState = {
  defaultProductLookUUID: null,
  productLookViews: [],
  productUUID: null,
  selectedDGCTemplatePages: [],
  selectedDGCUUID: null,
};

const digitalGreetingCardsReducer: Reducer<
  DigitalGreetingCardsState,
  DigitalGreetingCardsActions
> = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_DIGITAL_GREETING_CARDS: {
      const {
        default_product_look_id: defaultProductLookUUID,
        product_look_views: productLookViews,
        id: productUUID,
      } = action.payload;

      return {
        ...state,
        defaultProductLookUUID,
        productLookViews: productLookViews || initialState.productLookViews,
        productUUID,
      };
    }

    case ActionTypes.RECEIVE_CARD_TEMPLATE: {
      const { pages } = action.payload;

      return {
        ...state,
        selectedDGCTemplatePages: pages,
      };
    }

    case ActionTypes.SET_SELECTED_DGC_UUID: {
      return {
        ...state,
        selectedDGCUUID: action.payload,
      };
    }

    default:
      return state;
  }
};

export default digitalGreetingCardsReducer;
