import { Reducer } from 'redux';
import type {
  WRegistryCollectionItemView,
  WRegistryCollectionSearchView,
} from '@zola/svc-web-api-ts-client';
import * as ActionTypes from '../actions/types/ManageRegistryActionTypes';
import { ManageRegistryActions } from '../actions/ManageRegistryActions';
import { arrangeCollections } from '../util/registry';

const initialState = {
  registryKey: '',
  collectionObjectId: '',
  sort: '',
  filters: {} as Record<string, string[]>,
  busy: false,
  collection: {} as WRegistryCollectionSearchView,
  selectedItem: {} as WRegistryCollectionItemView,
  editZolaGiftCard: false,
};

type State = typeof initialState;

export const manageRegistryReducer: Reducer<State, ManageRegistryActions> = (state, action) => {
  if (!state) return initialState;

  switch (action.type) {
    case ActionTypes.UPDATE_FILTERS:
      return {
        ...state,
        filters: {
          ...state?.filters,
          [action.payload.key]: state.filters[action.payload.key]?.includes(action.payload.value)
            ? state.filters[action.payload.key]?.filter((val) => val !== action.payload.value)
            : [...(state.filters[action.payload.key] || []), action.payload.value],
        },
      };
    case ActionTypes.CLEAR_FILTERS:
      return {
        ...state,
        filters: {},
      };
    case ActionTypes.UPDATE_SORT:
      return {
        ...state,
        sort: action.payload,
      };
    case ActionTypes.UPDATE_COLLECTION_OBJECT_ID:
      return {
        ...state,
        collectionObjectId: action.payload,
      };
    case ActionTypes.UPDATE_REGISTRY_KEY:
      return {
        ...state,
        registryKey: action.payload,
      };
    case ActionTypes.REQUEST_REGISTRY_COLLECTION:
      return {
        ...state,
        busy: true,
      };
    case ActionTypes.RECEIVE_REGISTRY_COLLECTION:
      return {
        ...state,
        busy: false,
        collection: action.payload,
      };
    case ActionTypes.SET_SELECTED_REGISTRY_ITEM:
      return {
        ...state,
        selectedItem: action.payload,
      };
    case ActionTypes.SET_EDIT_ZOLA_GIFT_CARD:
      return {
        ...state,
        editZolaGiftCard: action.payload,
      };
    case ActionTypes.RECEIVE_UPDATED_REGISTRY_ITEM: {
      const defaultCollection = state.collection.default_collection;
      const updatedItem = action.payload;

      const updatedDefaultCollection = defaultCollection?.map((collectionItem) => {
        if (collectionItem.object_id === updatedItem.object_id) {
          return updatedItem;
        }

        return collectionItem;
      });

      return {
        ...state,
        collection: {
          ...state.collection,
          default_collection: updatedDefaultCollection,
        },
      };
    }
    case ActionTypes.SWAP_REGISTRY_ITEMS: {
      const { current, target } = action.payload;
      return {
        ...state,
        collection: {
          ...state.collection,
          default_collection: arrangeCollections(
            state.collection.default_collection,
            current,
            target
          ),
        },
      };
    }
    case ActionTypes.REMOVE_REGISTRY_ITEM: {
      const { itemId } = action.payload;
      return {
        ...state,
        collection: {
          ...state.collection,
          default_collection: state.collection.default_collection?.filter(
            (item) => item.item_id !== itemId
          ),
        },
      };
    }
    default:
      return state;
  }
};
