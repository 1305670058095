import * as ActionTypes from '../actions/types/RegistryActionTypes';

const initialState = {
  busy: false,
  collectionInfo: null,
  test: null,
};

export default function registryReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.REQUEST_REGISTRY_INFO_BY_SLUG:
      return { ...state, busy: true };
    case ActionTypes.RECEIVED_REGISTRY_INFO_BY_SLUG:
      return { ...state, busy: false, ...action.payload };
    case ActionTypes.REQUEST_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID:
      return { ...state, busy: true, collectionItem: null };
    case ActionTypes.RECEIVED_REGISTRY_ITEM_BY_COLLECTION_ITEM_ID:
      return { ...state, busy: false, collectionItem: action.payload.data };
    case ActionTypes.REQUEST_REGISTRY_INFO_BY_COLLECTION_ITEM_ID:
      return { ...state, busy: true, collectionItemInfo: null };
    case ActionTypes.RECEIVED_REGISTRY_INFO_BY_COLLECTION_ITEM_ID:
      return { ...state, busy: false, collectionItemInfo: action.payload.data };
    case ActionTypes.REQUEST_COLLECTION_INFO_BY_REGISTRY_ID:
      return { ...state, busy: true, collectionInfo: null };
    case ActionTypes.RECEIVED_COLLECTION_INFO_BY_REGISTRY_ID:
      return { ...state, busy: false, collectionInfo: action.payload };
    case ActionTypes.REQUEST_REGISTRY_INFO_BY_ID:
      return { ...state, busy: true };
    case ActionTypes.RECEIVED_REGISTRY_INFO_BY_ID:
      return { ...state, busy: false, ...action.payload };
    default:
      return state;
  }
}
