import * as ActionTypes from '../actions/types/ChecklistActionTypes';

const initialState = {
  checklistLoading: true,
  guides: [],
};

const checklistReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.RECEIVE_CHECKLIST: {
      return { ...state, checklistLoading: true, ...action.payload };
    }
    case ActionTypes.REQUEST_CHECKLIST: {
      return { ...state, checklistLoading: true };
    }
    case ActionTypes.UPDATE_CHECKLIST: {
      const checklist = state.guides.push(...action.payload);
      return Object.assign({}, state, {
        checklist,
        checklistLoading: false,
      });
    }
    default:
      return state;
  }
};

export default checklistReducer;
