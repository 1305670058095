import React from 'react';
import { ThemeProvider } from '@emotion/react';

import COLORS, {
  COLORS_BRAND,
  COLORS_TEXT,
  COLOR_SEMANTIC,
  COLORS_BACKGROUND,
} from '@zola/zola-ui/src/styles/emotion/colors';
import COLORS3, { COLORS_GRAYS } from '@zola/zola-ui/src/styles/emotion/colors3';

import FONTS from '@zola/zola-ui/src/styles/emotion/fonts';
import SPACING from '@zola/zola-ui/src/styles/emotion/spacing';
import MEDIA_QUERY, {
  SCREEN_XS,
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_XL,
  SCREEN_MASSIVE,
} from '@zola/zola-ui/src/styles/emotion/mediaQuery';

export const theme = {
  COLORS3,
  COLORS,
  COLORS_BRAND,
  COLORS_TEXT,
  COLOR_SEMANTIC,
  COLORS_BACKGROUND,
  COLORS_GRAYS,
  FONTS,
  SPACING,
  MEDIA_QUERY,
  SCREEN_XS,
  SCREEN_SM,
  SCREEN_MD,
  SCREEN_LG,
  SCREEN_XL,
  SCREEN_MASSIVE,
};

export type ZolaTheme = typeof theme;

export const ZolaThemeProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);
