import * as ActionTypes from '../actions/types/UploadcareActionTypes';
import { UploadcareActions } from '../actions/UploadcareActions';

interface UploadcareReducerType {
  uploadcareFiles: any;
  zolaMetadata: any;
}

const initialState = {
  uploadcareFiles: {},
  zolaMetadata: {},
};

const uploadcareReducer = (
  state = initialState,
  action: UploadcareActions
): UploadcareReducerType => {
  switch (action.type) {
    case ActionTypes.UPLOADCARE_TRANSFER_STARTED: {
      // eslint-disable-next-line
      // @ts-ignore
      const { uploadcareFile } = action.payload;
      return {
        ...state,
        uploadcareFiles: {
          ...state.uploadcareFiles,
          [uploadcareFile.cdnUrl]: uploadcareFile,
        },
      };
    }
    case ActionTypes.UPLOADCARE_TRANSFER_COMPLETE: {
      // eslint-disable-next-line
      // @ts-ignore
      const { metadata } = action.payload;

      // If metadata is null (i.e. API error occurred), don't save it
      if (!metadata) return state;

      return {
        ...state,
        zolaMetadata: {
          ...state.zolaMetadata,
          [metadata.uuid]: {
            ...metadata,
            loading: false,
          },
        },
      };
    }
    default:
      return state;
  }
};

export default uploadcareReducer;
