import * as ActionTypes from '../actions/types/ReviewActionTypes';

const initialState = {
  busy: false,
  termAccepted: false,
};

const reviewReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_REVIEW_STATUS: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVED_REVIEW_STATUS: {
      return {
        ...state,
        busy: false,
        status: action.payload,
        termAccepted: action.payload && action.payload.accepted_conditions,
      };
    }
    case ActionTypes.REQUEST_ELIGIBLE_PRODUCT: {
      return { ...state, busy: true };
    }
    case ActionTypes.RECEIVED_ELIGIBLE_PRODUCT: {
      const products = action.payload.reviews.map((product) => ({
        ...product,
        review_user: action.payload.review_user,
      }));
      return {
        ...state,
        busy: false,
        products,
        termAccepted: action.payload.review_user.accepted_conditions,
      };
    }
    case ActionTypes.RECEIVED_REVIEW_DETAILS: {
      return {
        ...state,
        busy: false,
        activeReview: action.payload,
        termAccepted: action.payload.review_user.accepted_conditions,
      };
    }
    case ActionTypes.SET_ACTIVE_PRODUCT: {
      return { ...state, activeProduct: action.payload };
    }

    case ActionTypes.UNSET_ACTIVE_PRODUCT: {
      const { activeProduct, ...props } = state;
      return { ...props };
    }

    case ActionTypes.UNSET_ACTIVE_REVIEW: {
      const { activeReview, ...props } = state;
      return { ...props };
    }

    case ActionTypes.RECEIVED_UPDATE_REVIEW: {
      return { ...state, busy: false };
    }
    case ActionTypes.ACCEPT_CONDITION: {
      return { ...state, termAccepted: action.payload };
    }
    default:
      return state;
  }
};

export default reviewReducer;
