export const HYDRATE_OVERVIEW_MESSAGES = 'zola/overview/HYDRATE_OVERVIEW_MESSAGES';
export const OVERVIEW_PLANNER_STATS = 'zola/overview/OVERVIEW_PLANNER_STATS';
export const MESSAGES_AND_PLANNER_IS_LOADING = 'zola/overview/MESSAGES_AND_PLANNER_IS_LOADING';
export const REQUEST_PRODUCTS = 'zola/overview/REQUEST_PRODUCTS';
export const REQUEST_ADDITIONAL_PRODUCTS = 'zola/overview/REQUEST_ADDITIONAL_PRODUCTS';
export const RECEIVE_PRODUCTS = 'zola/overview/RECEIVE_PRODUCTS';
export const RECEIVE_ADDITIONAL_PRODUCTS = 'zola/overview/RECEIVE_ADDITIONAL_PRODUCTS';
export const HYDRATE_PLANNER_STATS = 'zola/overview/HYDRATE_PLANNER_STATS';
export const SET_USING_DEFAULT_PARAMETERS_FALSE =
  'zola/overview/SET_USING_DEFAULT_PARAMETERS_FALSE';
