import _xor from 'lodash/xor';
import * as ActionTypes from '../actions/types/RegistryCollectionActionTypes';

const initialState = {
  addingProduct: false,
  requestingCollection: false,
  removingProduct: false,
  products: [],

  busy: false,
  initialized: false,
  defaultCollectionObjectId: undefined,
  defaultCollection: [],
  otherCollections: [],
  facets: [],
  selectedFacetValues: {},
  sort: '',
  isFlattenedView: false,
  selectedCollectionObject: null,
  collectionList: [],
};

const registryCollectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_ADD_TO_REGISTRY: {
      return { ...state, addingProduct: true, ...action.payload };
    }
    case ActionTypes.RECEIVE_ADD_TO_REGISTRY: {
      return { ...state, addingProduct: false, ...action.payload };
    }
    case ActionTypes.REQUEST_SEARCH: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.SELECT_COLLECTION: {
      return Object.assign({}, state, { selectedCollectionObject: action.payload });
    }
    case ActionTypes.RECEIVE_SEARCH: {
      return Object.assign({}, state, {
        busy: false,
        initialized: true,
        facets: action.payload.facets,
        defaultCollection: action.payload.default_collection,
        otherCollections: action.payload.other_collections,
        collectionList: action.payload.collection_list,
      });
    }
    case ActionTypes.TOGGLE_FACET_VALUE: {
      const { facetKey, valueKey } = action.payload;
      const selectedFacetValues = { ...state.selectedFacetValues } || {};
      selectedFacetValues[facetKey] = _xor(selectedFacetValues[facetKey], [valueKey]);
      if (selectedFacetValues[facetKey].length === 0) {
        delete selectedFacetValues[facetKey];
      }
      return Object.assign({}, state, { selectedFacetValues });
    }
    case ActionTypes.RESET_ALL_FACET_VALUE: {
      const selectedFacetValues = {};
      return Object.assign({}, state, { selectedFacetValues });
    }

    case ActionTypes.RESET_FACET_VALUE: {
      const { selectedFacetValues } = state;
      delete selectedFacetValues[action.payload];
      const newSelectedFacetValues = Object.assign({}, selectedFacetValues);
      return Object.assign({}, state, { selectedFacetValues: newSelectedFacetValues });
    }
    default:
      return state;
  }
};

export default registryCollectionReducer;
