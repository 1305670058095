import * as ActionTypes from '../actions/types/PlannerActionTypes';

const initialState = {
  display: false,
  type: '',
  requiredFieldsError: false,
};

const plannerReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.DISPLAY_PLANNER_DRAWER: {
      return { ...state, display: action.data.display, type: action.data.type };
    }
    case ActionTypes.CLOSE_PLANNER_DRAWER: {
      return { ...state, display: action.data.display };
    }
    case ActionTypes.SHOW_REQUIRED_FIELDS_ERROR: {
      return { ...state, requiredFieldsError: true };
    }
    case ActionTypes.HIDE_REQUIRED_FIELDS_ERROR: {
      return { ...state, requiredFieldsError: false };
    }
    case ActionTypes.HYDRATE_PLANNER_SUGGESTIONS: {
      return { ...state, suggestions: action.data };
    }
    default:
      return state;
  }
};

export default plannerReducer;
