import { createAction } from '@reduxjs/toolkit';

import type {
  WAlbumView,
  WBrandCategoryView,
  WBrandDirectoryView,
  WCardSuiteSearchView,
  WCategoryView,
  WShopSearchResultView,
} from '@zola/svc-web-api-ts-client';

const REQUEST_CATEGORIES = 'zola/registryCollection/REQUEST_CATEGORIES';
const RECEIVE_CATEGORIES = 'zola/registryCollection/RECEIVE_CATEGORIES';
const REQUEST_DIRECTORY = 'zola/registryCollection/REQUEST_DIRECTORY';
const RECEIVE_DIRECTORY = 'zola/registryCollection/RECEIVE_DIRECTORY';

export const requestCategories = createAction(REQUEST_CATEGORIES);
export const receiveCategories = createAction<WBrandCategoryView[]>(RECEIVE_CATEGORIES);
export const requestDirectory = createAction(REQUEST_DIRECTORY);
export const receiveDirectory = createAction<WBrandDirectoryView>(RECEIVE_DIRECTORY);

const RECEIVE_ALBUMS = 'zola/shop/RECEIVE_ALBUMS';
const RECEIVE_PAPER = 'zola/shop/RECEIVE_PAPER';
const REQUEST_PRODUCTS = 'zola/shop/REQUEST_PRODUCTS';
const RECEIVE_PRODUCTS = 'zola/shop/RECEIVE_PRODUCTS';
const REQUEST_CATEGORY_TREE = 'zola/shop/REQUEST_CATEGORY_TREE';
const RECEIVE_CATEGORY_TREE = 'zola/shop/RECEIVE_CATEGORY_TREE';

export const receiveAlbums = createAction<WAlbumView[]>(RECEIVE_ALBUMS);
export const receivePaperBySearch = createAction<WCardSuiteSearchView>(RECEIVE_PAPER);
export const requestProductBySearch = createAction(REQUEST_PRODUCTS);
export const receivedProductBySearch = createAction<WShopSearchResultView>(RECEIVE_PRODUCTS);
export const requestCategoryTree = createAction(REQUEST_CATEGORY_TREE);
export const receiveCategoryTree = createAction<WCategoryView[]>(RECEIVE_CATEGORY_TREE);
