import * as ActionTypes from '../actions/types/ProductActionTypes';

const initialState = {};
const productReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_DEFAULT_GIFT_CARD_BY_LOOK_KEY:
      return initialState;
    case ActionTypes.RECEIVED_DEFAULT_GIFT_CARD_BY_LOOK_KEY:
      return Object.assign({}, state, { defaultGiftCard: action.payload });
    case ActionTypes.REQUEST_PRODUCTS:
      return {};
    case ActionTypes.RECEIVED_PRODUCTS:
      return { products: action.payload };
    default:
      return state;
  }
};

export default productReducer;
