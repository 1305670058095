export const UPDATE_FILTERS = 'zola/manageRegistry/UPDATE_FILTERS' as const;
export const CLEAR_FILTERS = 'zola/manageRegistry/CLEAR_FILTERS' as const;
export const UPDATE_SORT = 'zola/manageRegistry/UPDATE_SORT' as const;
export const UPDATE_COLLECTION_OBJECT_ID =
  'zola/manageRegistry/UPDATE_COLLECTION_OBJECT_ID' as const;
export const UPDATE_REGISTRY_KEY = 'zola/manageRegistry/UPDATE_REGISTRY_KEY' as const;
export const REQUEST_REGISTRY_COLLECTION =
  'zola/manageRegistry/REQUEST_REGISTRY_COLLECTION' as const;
export const RECEIVE_REGISTRY_COLLECTION =
  'zola/manageRegistry/RECEIVE_REGISTRY_COLLECTION' as const;
export const SET_SELECTED_REGISTRY_ITEM = 'zola/manageRegistry/SET_SELECTED_REGISTRY_ITEM' as const;
export const SET_EDIT_ZOLA_GIFT_CARD = 'zola/manageRegistry/SET_EDIT_ZOLA_GIFT_CARD' as const;
export const RECEIVE_UPDATED_REGISTRY_ITEM =
  'zola/manageRegistry/RECEIVE_UPDATED_REGISTRY_ITEM' as const;
export const SWAP_REGISTRY_ITEMS = 'zola/manageRegistry/SWAP_REGISTRY_ITEMS' as const;
export const REMOVE_REGISTRY_ITEM = 'zola/manageRegistry/REMOVE_REGISTRY_ITEM' as const;
