import type {
  CheckoutData,
  Details,
  WCartItemDetailView,
  WGetCartView,
  WCreditCardView,
} from '@zola/svc-web-api-ts-client';
import { Reducer } from 'redux';
import type { CamelCasedPropertiesDeep } from 'type-fest';
import {
  receiveCartAction,
  receivedSavedCreditCardsAction,
  receiveDeletedItemsAction,
  receiveInitialCartDetailsAction,
  receiveShippingValidationAction,
  receiveUpdatedCartAction,
  requestCartAction,
  requestUpdatedCartAction,
  receiveRemoveDefaultCreditCard,
} from '../actions/types/CartActionTypes';

export type Promo = {
  code?: string;
  label?: string;
  terms?: string;
};
export type CartState = {
  busy: boolean;
  cartId?: string;
  cartMessage?: string | null;
  checkoutData?: CamelCasedPropertiesDeep<CheckoutData>;
  details?: CamelCasedPropertiesDeep<Details>;
  freeShipping?: { amountLeft?: number; metThreshold?: boolean } | null;
  initialized: boolean;
  items: CamelCasedPropertiesDeep<WCartItemDetailView>[];
  promo?: Promo;
  /** @deprecated - this doesnt' appear to be read anywhere */
  size: number;
  totals: {
    subtotal?: number;
    tax?: number;
    deliverySurcharge?: number;
    supplyChainFee?: number;
    shipping?: number;
    creditApplied?: number;
    discountsApplied?: number;
    handlingFee?: number;
    total?: number;
    amountSaved?: number;
  };
  validateShipping?: boolean;
  shippingError?: string | undefined;

  defaultCreditCard?: CamelCasedPropertiesDeep<WCreditCardView>;

  deletedCardItems?: CamelCasedPropertiesDeep<WGetCartView>['items'];
};

const initialState = {
  details: {},
  busy: false,
  initialized: false,
  size: 0,
  items: [],
  totals: {},
  validateShipping: true,
  freeShipping: null, // temporary
};

const cartReducer: Reducer<CartState> = (state = initialState, action) => {
  if (requestCartAction.match(action) || requestUpdatedCartAction.match(action)) {
    return { ...state, busy: true };
  }
  if (receiveCartAction.match(action)) {
    return {
      ...state,
      busy: false,
      initialized: true,
      cartMessage: action.payload?.cartMessage,
      size: action.payload?.items?.length || 0,
      items: action.payload?.items || [],
      totals: action.payload?.totals ?? {},
      freeShipping: action.payload?.freeShipping ?? null,
    };
  }
  if (receiveUpdatedCartAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cartMessage,
      details: action.payload?.details,
      items: action.payload?.items || [],
      promo: action.payload?.promo,
      totals: action.payload?.totals ?? {},
    };
  }
  if (receiveDeletedItemsAction.match(action)) {
    return { ...state, deletedCartItems: action.payload };
  }

  if (receiveInitialCartDetailsAction.match(action)) {
    return {
      ...state,
      cartMessage: action.payload?.cart?.cartMessage,
      details: action.payload?.cart?.details,
      promo: action.payload?.cart?.promo,
      totals: action.payload?.cart?.totals ?? {},
      checkoutData: action.payload?.checkoutData,
      cartId: action.payload?.checkoutData && action.payload.checkoutData.cartId,
    };
  }

  if (receivedSavedCreditCardsAction.match(action)) {
    const cards = action.payload;
    const defaultCreditCard = cards?.[0];
    return {
      ...state,
      defaultCreditCard,
    };
  }

  if (receiveRemoveDefaultCreditCard.match(action)) {
    return {
      ...state,
      defaultCreditCard: undefined,
    };
  }

  if (receiveShippingValidationAction.match(action)) {
    const validateShipping =
      action.payload &&
      !action.payload.allowBypass &&
      (!action.payload.data || !action.payload.data.isValidating);
    const shippingError = action.payload.data && action.payload.data.userMessage;
    return {
      ...state,
      shippingError,
      validateShipping,
    };
  }

  return state;
};

export default cartReducer;
