import * as ActionTypes from '../actions/types/ModalActionTypes';

const initialState = {
  isVisible: false,
  modalType: null,
  modalProps: {},
  modalOptions: {},
  busy: false,
};

export default function modalReducer(state = initialState, action = {}) {
  switch (action.type) {
    case ActionTypes.HIDE_MODAL:
      return {
        ...state,
        modalType: null,
        modalProps: {},
        modalOptions: {},
        isVisible: false,
        busy: false,
      };
    case ActionTypes.SHOW_MODAL:
      return {
        ...action.payload,
        isVisible: true,
        busy: false,
      };
    case ActionTypes.IS_BUSY:
      return {
        ...state,
        busy: action.payload,
      };
    default:
      return state;
  }
}
