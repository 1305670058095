import type {
  WAvailablePersonalizationListView,
  WUserSelectionListView,
} from '@zola/svc-web-api-ts-client';

export const RECEIVED_AVAILABLE_SKU_PERSONALIZATION =
  'zola/personalization/RECEIVED_AVAILABLE_SKU_PERSONALIZATION';
export const RECEIVED_SAVE_PERASONALIZATION_FORM_DATA =
  'zola/personalization/RECEIVED_SAVE_PERASONALIZATION_FORM_DATA';
export const RECEIVED_UPDATE_SKU_PERSONALIZATION =
  'zola/personalizaton/RECEIVED_UPDATE_SKU_PERSONALIZATION';
export const RECEIVED_RESET_PERASONALIZATION_FORM_DATA =
  'zola/personalizaton/RECEIVED_RESET_PERASONALIZATION_FORM_DATA';

export type ReceivedAvailableSkuPersonalizationType = {
  type: typeof RECEIVED_AVAILABLE_SKU_PERSONALIZATION;
  data: {
    response: WAvailablePersonalizationListView;
    productName?: string;
    productBrandName?: string;
  };
};

export type ReceivedSavePersonalizationFormDataType = {
  type: typeof RECEIVED_SAVE_PERASONALIZATION_FORM_DATA;
  payload: any;
};

export type ReceivedUpdateSkuPersonalizationType = {
  type: typeof RECEIVED_UPDATE_SKU_PERSONALIZATION;
  payload: WUserSelectionListView | null;
};

export type ReceivedResetPersonalizationFormDataType = {
  type: typeof RECEIVED_RESET_PERASONALIZATION_FORM_DATA;
};

export type PersonalizationActions =
  | ReceivedAvailableSkuPersonalizationType
  | ReceivedSavePersonalizationFormDataType
  | ReceivedUpdateSkuPersonalizationType
  | ReceivedResetPersonalizationFormDataType;
