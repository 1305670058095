import * as ActionTypes from '../actions/types/NavActionTypes';

const initialState = {
  categories: {},
  categoriesLoaded: false,
};

const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case ActionTypes.REQUEST_CATEGORY: {
      return Object.assign({}, state, { busy: true });
    }
    case ActionTypes.RECEIVE_CATEGORY: {
      return Object.assign({}, state, {
        busy: false,
        categories: action.payload,
        categoriesLoaded: true,
      });
    }
    default:
      return state;
  }
};

export default navReducer;
