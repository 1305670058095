import * as ActionTypes from '../actions/types/RegistryBenefitsActionTypes';

const initialState = {
  perks: [],
};

export default function registryReducer(state = initialState, action) {
  switch (action.type) {
    case ActionTypes.RECEIVE_BONUS_GIFTS:
      return { ...state, perks: action.payload };
    default:
      return state;
  }
}
