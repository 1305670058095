import React from 'react';
import { Provider } from 'react-redux';
import LogRocket from 'logrocket';

import { enhancedContext } from '@zola/tracking-contracts/src/plugins/enhancedContext';
import { enhancedTraits } from '@zola/tracking-contracts/src/plugins/enhancedTraits';
import { mixpanelContext } from '@zola/tracking-contracts/src/plugins/mixpanelContext';
import { useEffectOnce } from '@zola/zola-ui/src/hooks/useEffectOnce';
import { ZolaThemeProvider } from '@/ZolaThemeProvider';
import {
  continueExistingLogRocketSession,
  initLogRocketSession,
} from '@zola-helpers/client/dist/es/util/logrocket/logRocketUtils';
import { continueExistingSessionReplay } from '@zola-helpers/client/dist/es/util/mixpanel/sessionReplay';

import { AppProps } from 'next/app';
import { useStore } from '../src/store/nextStore';

import '@/stylesheets/core.less';

type CustomAppProps = AppProps & {
  err?: unknown;
};

// Initialize Segment before any other rendering
if (typeof window !== 'undefined' && window.analytics) {
  continueExistingSessionReplay();
  window.analytics.register(enhancedContext, enhancedTraits(), mixpanelContext());
  window.analytics.page();
}

const CustomApp = ({ Component, pageProps, err }: CustomAppProps) => {
  const store = useStore(pageProps.initialReduxState && JSON.parse(pageProps.initialReduxState));

  useEffectOnce(() => {
    if (typeof window !== 'undefined') {
      window.LogRocket = LogRocket;
      continueExistingLogRocketSession();
    }
    // Initialize LogRocket
    const logRocketActive = false;
    if (logRocketActive) {
      initLogRocketSession(true, {
        initEventName: 'REGISTRY_BASE',
      });
    }
  });

  return (
    <ZolaThemeProvider>
      <Provider store={store}>
        <Component {...pageProps} err={err} />
      </Provider>
    </ZolaThemeProvider>
  );
};

export default CustomApp;
